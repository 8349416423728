@font-face {
  font-family: 'Proxima Nova';
  font-weight: normal;
  font-style: normal;
  src: url('../static/fonts/proximanova-light-webfont.eot?v=1.0.0'); /* IE9 Compat Modes */
  src: url('../static/fonts/proximanova-light-webfont.woff2?v=1.0.0')
      format('woff2'),
    /* Super Modern Browsers */
      url('../static/fonts/proximanova-light-webfont.woff?v=1.0.0')
      format('woff'),
    /* Pretty Modern Browsers */
      url('../static/fonts/proximanova-light-webfont.ttf?v=1.0.0')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 500;
  font-style: normal;
  src: url('../static/fonts/proximanova-regular-webfont.eot?v=1.0.0'); /* IE9 Compat Modes */
  src: url('../static/fonts/proximanova-regular-webfont.woff2?v=1.0.0')
      format('woff2'),
    /* Super Modern Browsers */
      url('../static/fonts/proximanova-regular-webfont.woff?v=1.0.0')
      format('woff'),
    /* Pretty Modern Browsers */
      url('../static/fonts/proximanova-regular-webfont.ttf?v=1.0.0')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: bold;
  font-style: normal;
  src: url('../static/fonts/proximanova-bold-webfont.eot?v=1.0.0'); /* IE9 Compat Modes */
  src: url('../static/fonts/proximanova-bold-webfont.woff2?v=1.0.0')
      format('woff2'),
    /* Super Modern Browsers */
      url('../static/fonts/proximanova-bold-webfont.woff?v=1.0.0')
      format('woff'),
    /* Pretty Modern Browsers */
      url('../static/fonts/proximanova-bold-webfont.ttf?v=1.0.0')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Proxima Nova Extra Condensed';
  font-weight: lighter;
  font-style: normal;
  src: url('../static/fonts/proximanovaexcn-light-webfont.eot?v=1.0.0'); /* IE9 Compat Modes */
  src: url('../static/fonts/proximanovaexcn-light-webfont.woff2?v=1.0.0')
      format('woff2'),
    /* Super Modern Browsers */
      url('../static/fonts/proximanovaexcn-light-webfont.woff?v=1.0.0')
      format('woff'),
    /* Pretty Modern Browsers */
      url('../static/fonts/proximanovaexcn-light-webfont.ttf?v=1.0.0')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Proxima Nova Extra Condensed';
  font-weight: normal;
  font-style: normal;
  src: url('../static/fonts/proximanovaexcn-medium-webfont.eot?v=1.0.0'); /* IE9 Compat Modes */
  src: url('../static/fonts/proximanovaexcn-medium-webfont.woff2?v=1.0.0')
      format('woff2'),
    /* Super Modern Browsers */
      url('../static/fonts/proximanovaexcn-medium-webfont.woff?v=1.0.0')
      format('woff'),
    /* Pretty Modern Browsers */
      url('../static/fonts/proximanovaexcn-medium-webfont.ttf?v=1.0.0')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Proxima Nova Extra Condensed';
  font-weight: bold;
  font-style: normal;
  src: url('../static/fonts/proximanovaexcn-bold-webfont.eot?v=1.0.0'); /* IE9 Compat Modes */
  src: url('../static/fonts/proximanovaexcn-bold-webfont.woff2?v=1.0.0')
      format('woff2'),
    /* Super Modern Browsers */
      url('../static/fonts/proximanovaexcn-bold-webfont.woff?v=1.0.0')
      format('woff'),
    /* Pretty Modern Browsers */
      url('../static/fonts/proximanovaexcn-bold-webfont.ttf?v=1.0.0')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Proxima Nova Extra Condensed';
  font-weight: 500;
  font-style: bolder;
  src: url('../static/fonts/proximanovaexcn-bold-webfont.eot?v=1.0.0'); /* IE9 Compat Modes */
  src: url('../static/fonts/proximanovaexcn-bold-webfont.woff2?v=1.0.0')
      format('woff2'),
    /* Super Modern Browsers */
      url('../static/fonts/proximanovaexcn-bold-webfont.woff?v=1.0.0')
      format('woff'),
    /* Pretty Modern Browsers */
      url('../static/fonts/proximanovaexcn-bold-webfont.ttf?v=1.0.0')
      format('truetype'); /* Safari, Android, iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../static/fonts/open-sans-v23-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../static/fonts/open-sans-v23-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../static/fonts/open-sans-v23-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../static/fonts/open-sans-v23-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../static/fonts/open-sans-v23-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../static/fonts/open-sans-v23-latin-regular.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../static/fonts/open-sans-v23-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../static/fonts/open-sans-v23-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../static/fonts/open-sans-v23-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../static/fonts/open-sans-v23-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../static/fonts/open-sans-v23-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../static/fonts/open-sans-v23-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

.font-proxima-condensed {
  font-family: 'Proxima Nova Extra Condensed', sans-serif;
}

.font-proxima-bold {
  font-family: 'Proxima Nova', 'Helvetica Neue', 'Roboto', Helvetica, Arial,
    sans-serif;
  font-weight: bold;
}

.font-proxima-reg {
  font-family: 'Proxima Nova', 'Helvetica Neue', 'Roboto', Helvetica, Arial,
    sans-serif;
  font-weight: normal;
}

.font-body {
  font-family: 'Open Sans', 'Helvetica Neue', 'Roboto', Helvetica, Arial,
    sans-serif;
  font-weight: normal;
}

.font-body-bold {
  font-family: 'Open Sans', 'Helvetica Neue', 'Roboto', Helvetica, Arial,
    sans-serif;
  font-weight: bold;
}
