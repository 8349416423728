@tailwind base;

@tailwind components;

@tailwind utilities;

@import './face.css';

html {
  scroll-behavior: smooth;
  min-height: 100%;
}

body {
  font-family: 'Open Sans';
}

#mountNode > div {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main-content-container {
  flex: 1;
  justify-content: flex-start;
}

.break-word {
  word-wrap: break-word;
}

.no-wrap {
  white-space: nowrap;
}

.flex-even {
  flex-basis: 0;
  flex-grow: 1;
}

.font-proxima-condensed {
  font-family: 'Proxima Nova Extra Condensed';
}

.blur-sm {
  filter: blur(4px);
}

.f-search {
  @apply border-2 rounded-4xl border-darkestGrey font-bold text-lg text-darkerGrey px-3 py-3 mr-4;
}

.h-search {
  @apply text-gray-800 text-lg;
}

.location-field {
  @apply border-2 border-black rounded px-4 py-2;
}

.location-dropdown {
  @apply border-2 border-black rounded px-4 py-2 bg-white;
}

.menu-link {
  transition-property: border-bottom;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;

  display: inline-block;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
}

.menu-link.selected,
.menu-link:hover {
  border-bottom: 2px solid rgba(255, 255, 255, 1);
}

div.h-record-list {
  @apply font-bold text-darkerGrey uppercase text-sm tracking-wide mb-6 whitespace-nowrap;

  font-family: 'Proxima Nova';
}

div.record-title-small {
  @apply font-bold text-darkerGrey uppercase text-sm tracking-wide mb-0 mr-2 w-6/12 text-left align-text-top;

  font-family: 'Proxima Nova';
}

div.c-record-list {
  @apply text-sm cursor-pointer;
}

span.h-record-detail {
  @apply font-extrabold text-xl;
  font-family: 'Proxima Nova Extra Condensed';
}

h1.pageTitle {
  @apply text-pageTitle text-center;

  font-family: 'Proxima Nova';
}

.laptop {
  max-height: 500px;
}

.press-strip-partners {
  position: absolute;
  bottom: -49px;
}

.cta-block .cta-block-link {
  border-radius: 3px;
  box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
}

.cta-block .cta-block-link:hover {
  box-shadow: 0 11px 21.84px 2.16px rgba(0, 0, 0, 0.15);
}

.cta-block .cta-block-link:hover .right-arrow {
  background: #1589ec;
}

.cta-block .cta-block-link:after {
  position: absolute;
  top: 50%;
  right: 4px;

  width: 0;
  height: 0;

  content: '';
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid #fff;
}

.cta-block .cta-block-content:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
  -webkit-transition: border 0.2s linear;
  transition: border 0.2s linear;

  border: 1px solid #3d76b7;
  border-radius: 3px;
}

.cta-block .cta-block-link:hover .cta-block-content:after {
  border: 3px solid #1589ec;
}

.button-normal-height {
  @apply py-3 px-12;
}

.button-thin {
  @apply py-1 px-12;
}

.button-yesno {
  @apply py-1 px-98px;
}

.scan-button-defaults {
  @apply inline-block text-xl font-bold bg-button text-white text-center align-middle rounded-full whitespace-nowrap shadow-lg transition-colors duration-150 ease-out cursor-pointer;
}

.button-standard {
  @apply scan-button-defaults button-normal-height;
}

.button-standard:hover {
  @apply bg-buttonHover;
}

.button-standard-thin {
  @apply scan-button-defaults button-thin border-2 border-button;
}

.button-standard-thin:hover {
  @apply bg-buttonHover border-buttonHover;
}

.button-standard-thin-inverted {
  @apply scan-button-defaults button-thin bg-invertedButton border-2 border-button;
}

.button-standard-thin-inverted:hover {
  @apply bg-invertedButtonHover;
}

.button-standard-thin-secondary {
  @apply scan-button-defaults button-thin bg-darkGrey border-2 border-darkGrey;
}

.button-standard-thin-secondary:hover {
  @apply bg-darkerGrey;
}

.button-yesno-thin {
  @apply scan-button-defaults button-yesno border-2 border-button;
}

.button-yesno-thin:hover {
  @apply bg-buttonHover border-buttonHover;
}

.button-yesno-thin-secondary {
  @apply scan-button-defaults button-yesno bg-darkGrey border-2 border-darkGrey;
}

.button-yesno-thin-secondary:hover {
  @apply bg-darkerGrey;
}

h2.sub-title {
  @apply text-4xl font-bold text-center;

  font-family: 'Proxima Nova';
}

.page-shadow {
  box-shadow: inset 0 7px 18px -6px rgb(0 0 0 / 20%);
}

a.round-button {
  @apply py-2 px-24 font-bold rounded-full uppercase cursor-pointer;
}

a.remove-info-button {
  @apply py-4 px-12 font-bold rounded-full cursor-pointer bg-mintGreen text-black shadow-defaultButton tracking-widest font-bold;
}

.progressBar {
  transition-property: width;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
}

.scanning-block {
  box-shadow: 0 1px 3px 1px rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}

.results-grid {
  display: grid;
  grid-template-columns: min-content repeat(3, minmax(0, 1fr)) repeat(
      3,
      min-content
    );
  column-gap: 0;
  row-gap: 0;
}

.results-grid-small {
  display: grid;
  grid-template-columns: repeat(7, auto);
  column-gap: 0;
  row-gap: 0;
}

.results-grid .table-row {
  display: contents;
}

.results-grid .table-row:nth-of-type(even) > div {
  @apply bg-lightestGrey;
}

.expanded-grid {
  @apply bg-lightestBlue px-4 py-4;
  display: grid;
  grid-template-columns: 33% 34% 33%;
  column-gap: 0;
  row-gap: 0;
}

.rotating-icon {
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.textPlaceholder {
  display: block;
  min-height: 2rem;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeload;
  animation-name: placeload;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: #eeeeee;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, #eeeeee),
    color-stop(18%, #dddddd),
    color-stop(33%, #eeeeee)
  );
  background: -webkit-linear-gradient(
    left,
    #eeeeee 8%,
    #dddddd 18%,
    #eeeeee 33%
  );
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  -webkit-background-size: 800px 104px;
  background-size: 1200px 104px;
  position: relative;
  margin: 1rem;
}

@-webkit-keyframes placeload {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

a.footer-button {
  @apply bg-footerButton inline-flex items-center font-semibold cursor-pointer h-10;

  padding: 0 1.5rem 0 0.5rem;
  border-radius: 1.375rem;
  letter-spacing: 0.8px;
  font-size: 14px;
  box-shadow: 0 10px 20px -10px rgb(12 5 62 / 60%);
}

a.footer-button span {
  @apply inline-block;
  @apply mr-4;
}

a.footer-button img {
  @apply align-middle;
}

a.footer-button .globe {
  display: inline-block;
  vertical-align: middle;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
}

a.lower-footer-link {
  @apply mr-4 text-footerColumnList block;
}

a.lower-footer-link-before:before {
  @apply inline-block mr-4;
  content: '\02219';
}

a.social-icon {
  @apply mr-4;
}

a.social-icon svg {
  height: 24px;
  width: 24px;
}

a.social-icon svg path {
  fill: #a8a4c3;
}

a.social-icon:hover svg path {
  fill: #fff;
}

p.footer-column-list-header {
  @apply uppercase text-footerColumnHead font-bold tracking-footerColumnHead leading-footerColumnHead min-h-footerColumnHead w-full;
}

li.footer-column-list-item {
  @apply pb-2 text-lighterGrey text-footerColumnList tracking-footerColumnList leading-footerColumnList w-full;
}

.section-header {
  @apply text-4xl font-extrabold text-center text-darkGrey w-9/12 mb-16;
}

.section-header-full-width {
  @apply text-4xl font-extrabold text-center text-darkGrey w-full mb-16;
}

.tabbed-carousel {
  @apply flex flex-auto flex-row w-full border border-lighterGrey;
}

.tabbed-carousel .tabs {
  @apply flex flex-col justify-start bg-lightestGrey;
}

.tabbed-carousel .tabs li {
  @apply relative flex flex-row justify-start items-center text-xl font-bold py-8 pl-12 bg-lightestGrey;

  border-top: 1px solid #f9f9f9;
  border-bottom: 1px solid #f9f9f9;
}

.tabbed-carousel .tabs li .selected-indicator {
  @apply absolute left-0 w-2 bg-transparent;

  top: -1;
  bottom: -1;
}

.tabbed-carousel .tabs li.selected {
  @apply bg-white text-midBlue border-b-2;

  border-top: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
}

.tabbed-carousel .tabs li.selected:after {
  position: absolute;
  top: 0;
  right: -1;
  bottom: 0;

  width: 1px;

  content: '';

  border: 1px solid #fff;
}

.tabbed-carousel .tabs li:first-child,
.tabbed-carousel .tabs li:first-child.selected {
  border-top: none;
}

.tabbed-carousel .tabs li.selected .selected-indicator {
  @apply bg-midBlue;
}

.tabbed-carousel .tabs .tab-icon {
  @apply w-8 h-8 fill-current text-midBlue mr-4;
}

.tabbed-carousel .content {
  @apply p-8 border-l border-lighterGrey;
}

.tabbed-carousel .tab-content {
  @apply w-full;

  /* https://observablehq.com/@rkaravia/css-trick-tabs-with-consistent-height */
  margin-right: -100%;
}

.tabbed-carousel .tab-content.visible {
  @apply block;
}

.legal-text ul ol,
.legal-text ul ul,
.legal-text ol ol,
.legal-text ol ul {
  @apply mx-8;
}

.legal-text li {
  @apply my-8 mx-4;
}

.legal-text p {
  @apply my-8;
}

.legal-text li > p {
  @apply ml-4 mt-0 mb-0;
}

.legal-text a {
  @apply text-midBlue underline;
}

#privacy-policy h2 {
  @apply text-2xl font-bold text-midBlue;
}

#terms-of-service h2 {
  @apply text-3xl font-bold text-midBlue;
}

#terms-of-service ol:not(.list-lowerAlpha) {
  counter-reset: item;
}

#terms-of-service ol:not(.list-lowerAlpha) > li {
  display: block;
}

#terms-of-service ol:not(.list-lowerAlpha) > li:before {
  content: counters(item, '.') '. ';
  counter-increment: item;
}

#terms-of-service > ol > li > strong {
  @apply text-midBlue;
}

#terms-of-service ol.list-decimal > li > ol.list-lowerAlpha > li,
#terms-of-service ul.list-circle > li {
  @apply m-4;
}

#terms-of-service li > p {
  @apply ml-4 mt-4 mb-0;
}

#cookie-policy li > p {
  @apply ml-0 mt-4 mb-0;
}

#cookie-policy > ol > li > strong {
  @apply text-midBlue;
}

#cookie-policy .grid {
  @apply border border-darkGrey border-l-0 border-t-0 mt-8;
}

#cookie-policy .grid div {
  @apply border border-darkGrey border-r-0 border-b-0 p-4 flex items-center;
}

#cookie-policy .grid div:nth-child(1),
#cookie-policy .grid div:nth-child(2) {
  @apply border-b-2;
}

/* ============================================================================
   The following styles are cribbed from an article on styling select elements.
   https://www.filamentgroup.com/lab/select-css.html
   ============================================================================ */
.select-css {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient.
		for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference

	*/
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
  display: none;
}
/* Hover style */
.select-css:hover {
  border-color: #888;
}
/* Focus style */
.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 10px 7px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  color: #222;
  outline: none;
}

/* Set options to normal weight */
.select-css option {
  font-weight: normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir='rtl'] .select-css,
:root:lang(ar) .select-css,
:root:lang(iw) .select-css {
  background-position: left 0.7em top 50%, 0 0;
  padding: 0.6em 0.8em 0.5em 1.4em;
}

/* Disabled styles */
.select-css:disabled,
.select-css[aria-disabled='true'] {
  color: graytext;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
}

.select-css:disabled:hover,
.select-css[aria-disabled='true'] {
  border-color: #aaa;
}
/* ==================================
          END SELECT STYLES
   ================================== */
