.google-img {
  width: 18px;
  height: 18px;
  margin-right: 24px;
}

.google-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  width: 200px;
  padding: 8px;
  border-radius: 100px;
  text-align: left;
}

.google-button:hover {
  background-color: #4f46e52b;
}

.google-text {
  font-size: 14px;
  letter-spacing: 0.22px;
  color: #0000008a;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}
